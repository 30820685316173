import "./App.css";
import React, { Component, Suspense } from "react";
import { HashRouter, BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";

const Home = React.lazy(() => import("./views/Home"));
const CancelSub = React.lazy(() => import("./views/CancelSub"));
const RefundIns = React.lazy(() => import("./views/RefundIns"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  return (
    <Routes>
      <Route exact path="/cancel-sub" name="Submit refund" element={<CancelSub />} />
      <Route exact path="/refund-instructions" name="How to request a refund" element={<RefundIns />} />
      <Route path="/" name="Home" element={<Home />} />
    </Routes>
  );
};
//Suspense
export default App;
